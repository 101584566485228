<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="align-start">
      <span>Потребность в калориях</span>
      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
        <v-simple-table class="team-table">
          <thead>
            <tr>
              <th class="text-left">
                Тип похудения
              </th>
              <th class="text-left">
                Калории
              </th>
              <!-- <th class="text-left">
              Recommended Marathon
              </th> -->
            </tr>
          </thead>
          <tbody v-if="caloricNeeds.balance !== 0">
            <tr>
              <td>
                <v-chip
                  small
                  color="success"
                  :class="`v-chip-light-bg secondary--text font-weight-semibold`"
                >
                  Поддержание
                </v-chip>
              </td>
              <td>
                <p class="font-weight-semibold mb-0">
                  <span>{{caloricNeeds.balance}}</span>
                </p>
              </td>
              <!-- <td class="d-flex align-center">
              </td> -->
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  color="success"
                  :class="`v-chip-light-bg success--text font-weight-semibold`"
                >
                  Здоровое похудение
                </v-chip>
              </td>
              <td>
                <p class="font-weight-semibold mb-0">
                  <span>{{caloricNeeds.mildWeightLoss}}</span>
                </p>
              </td>
              <!-- <td class="d-flex align-center">
                <available-marathons-dialog btnText="Смотреть"></available-marathons-dialog>
              </td> -->
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  color="success"
                  :class="`v-chip-light-bg warning--text font-weight-semibold`"
                >
                  Быстрое похудение
                </v-chip>
              </td>
              <td>
                <p class="font-weight-semibold mb-0">
                  <span>{{caloricNeeds.heavyWeightLoss}}</span>
                </p>
              </td>
              <!-- <td class="d-flex align-center">
                <available-marathons-dialog btnText="Смотреть"></available-marathons-dialog>
              </td> -->
            </tr>
          </tbody>
        </v-simple-table>
    </v-card-text>
    <v-card-actions v-if="caloricNeeds.balance == 0" class="mt-auto">
      <v-spacer></v-spacer>
     <user-info-form></user-info-form>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import AvailableMarathonsDialog from '../../components/dialogs/AvailableMarathonsDialog.vue'
import UserInfoForm from '../../components/dialogs/UserInfoForm.vue'

export default {
  components: {
    AvailableMarathonsDialog,
    UserInfoForm
  },
  setup() {
    const isDialogVisible = ref(false)
    const genderItems = ['Male', 'Female']
    const activityItems = ["sedentary", "light", "moderate", "active", "extreme"]
    const userAge = computed({
      get: () => store.state.user.user.age,
      set: value => {
        store.commit('user/setUserAge', value)
      },
    })
    const userGender = computed({
      get: () => store.state.user.user.gender,
      set: value => {
        store.commit('user/setUserGender', value)
      },
    })
    const userActivity = computed({
      get: () => store.state.user.user.activity,
      set: value => {
        store.commit('user/setUserActivity', value)
      },
    })
    const userHeight = computed({
      get: () => store.state.user.user.height,
      set: value => {
        store.commit('user/setUserHeight', value)
      },
    })
    const userWeight = computed({
      get: () => store.state.user.user.weight,
      set: value => {
        store.commit('user/setUserWeight', value)
      },
    })
    const caloricNeeds = computed(() => store.state.user.user.caloricNeeds)

    const getCaloricNeeds = () => {
      store.dispatch('user/getCaloricNeeds',{ height:userHeight.value, weight: userWeight.value, age: userAge.value, gender: userGender.value, activity: userActivity.value });
       isDialogVisible.value = false
    }

    return {
      caloricNeeds,
      genderItems,
      activityItems,
      userAge,
      userGender,
      userHeight,
      userWeight,
      userActivity,
      getCaloricNeeds,
      isDialogVisible,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.team-table {
  tbody {
    td {
      border-bottom: none !important;
    }
  }
}
</style>
