<template>
    <v-dialog
        v-model="isDialogVisible"
        persistent
        max-width="600px"
    >
    <template #activator="{ on, attrs }">
        <v-btn
        color="primary"
        dark
        outlined
        v-bind="attrs"
        v-on="on"
        >
        Рассчитать
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
        <span class="headline">Профиль пользователя</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <v-form>
            <v-text-field
                v-model="userAge"
                label="Возраст"
                outlined
                dense
                type="number"
                placeholder="Возраст"
            ></v-text-field>
            <v-autocomplete
                v-model="userGender"
                :items="genderItems"
                outlined
                label="Пол"
            ></v-autocomplete>
            <v-text-field
                v-model="userHeight"
                label="высота (см)"
                outlined
                dense
                type="number"
                placeholder="высота (см)"
            ></v-text-field>
            <v-text-field
                v-model="userWeight"
                label="Вес (кг)"
                outlined
                dense
                type="number"
                placeholder="Вес (кг)"
            ></v-text-field>
            <v-autocomplete
                v-model="userActivity"
                :items="activityItems"
                outlined
                label="Уровень активности"
            ></v-autocomplete>
            </v-form>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            outlined
            @click="isDialogVisible = false"
        >
            Закрывать
        </v-btn>
        <v-btn
            color="primary"
            outlined
            @click="calculateUserInfo()"
            :disabled="!userHeight || !userWeight"
        >
            Сохранять
        </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import AvailableMarathonsDialog from '../dialogs/AvailableMarathonsDialog.vue'

export default {
  components: {
    AvailableMarathonsDialog
  },
  setup() {
    const isDialogVisible = ref(false)
    const genderItems = [
        {
            text: 'Мужской',
            value: 'Male',
        },
        {
            text: 'Женский',
            value: 'Female',
        },  
    ]
    const activityItems = [
        {
            text: 'Неактивный',
            value: 'sedentary',
        }, 
        {
            text: 'Малоактивный',
            value: 'light',
        }, 
        {
            text: 'Средний',
            value: 'moderate',
        }, 
        {
            text: 'Активный',
            value: 'active',
        }, 
        {
            text: 'Очень активный',
            value: 'extreme',
        }, 
    ]
    const userAge = computed({
      get: () => store.state.user.user.age,
      set: value => {
        store.commit('user/setUserAge', value)
      },
    })
    const userGender = computed({
      get: () => store.state.user.user.gender,
      set: value => {
        store.commit('user/setUserGender', value)
      },
    })
    const userActivity = computed({
      get: () => store.state.user.user.activity,
      set: value => {
        store.commit('user/setUserActivity', value)
      },
    })
    const userHeight = computed({
      get: () => store.state.user.user.height,
      set: value => {
        store.commit('user/setUserHeight', value)
      },
    })
    const userWeight = computed({
      get: () => store.state.user.user.weight,
      set: value => {
        store.commit('user/setUserWeight', value)
      },
    })

    const calculateUserInfo = () => {
      store.dispatch('user/calculateUserInfo',{ height:userHeight.value, weight: userWeight.value, age: userAge.value, gender: userGender.value, activity: userActivity.value });
       isDialogVisible.value = false
    }

    return {
      genderItems,
      activityItems,
      userAge,
      userGender,
      userHeight,
      userWeight,
      userActivity,
      calculateUserInfo,
      isDialogVisible,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
