<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Индекс массы тела (ИМТ)</span>
      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="text-center">
          <div class="mx-auto">
              <v-img
                :src="require(`@/assets/images/charts/bmi-chart-${userBmiCategory}.png`)"
              ></v-img>
            </div>
    </v-card-text>
    <v-card-text
    class="text-center"
    v-if="userBmi > 0"
    >
      <h2 class="text-2xl font-weight-semibold">
       ИМТ: {{userBmi}}
      </h2>
    </v-card-text>
    <v-card-actions v-if="userBmiCategory == 'NA'">
      <v-spacer></v-spacer>
      <user-info-form></user-info-form>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import UserInfoForm from '../../components/dialogs/UserInfoForm.vue'
import { mdiDotsVertical } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
    UserInfoForm
  },
  setup() {
    const $vuetify = getVuetify()
    const isDialogVisible = ref(false)
    const userBmi = computed(() => store.state.user.user.bmi)
    const userBmiCategory = computed(() => store.state.user.user.bmiCategory)
    const userHeight = computed({
      get: () => store.state.user.user.height,
      set: value => {
        store.commit('user/setUserHeight', value)
      },
    })
    const userWeight = computed({
      get: () => store.state.user.user.weight,
      set: value => {
        store.commit('user/setUserWeight', value)
      },
    })

    const getBmi = () => {
      store.dispatch('user/getBmi',{ height:userHeight.value, weight: userWeight.value });
       isDialogVisible.value = false
    }
    
    const chartOptions = {
      colors: [$vuetify.theme.currentTheme.primary],
      chart: {
        type: 'radialBar',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            size: '55%',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -5,
              fontSize: '24px',
              fontWeight: 600,
              formatter(val) {
                return `${kFormatter((val * 40) / 100, 1)}`
              },
            },
          },
        },
      },
      stroke: {
        dashArray: 5,
      },
    }

    const chartData = [10]

    return {
      userHeight,
      isDialogVisible,
      userWeight,
      userBmi,
      getBmi,
      userBmiCategory,
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
